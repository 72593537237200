import Css from "./style.module.scss";

import React from "react";
import classNames from "classnames";

const Card = ({ className, children, ...restProps }) => {
  return (
    <div className={classNames(Css.card, className)} {...restProps}>
      {children}
    </div>
  );
};

export default React.memo(Card);
