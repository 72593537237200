import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import { Pagination, SelectPageSize } from "nlib/ui";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ActionsCell from "./lib/ActionsCell";
import ArchiveActions from "actions/ArchiveActions";
import AutoTooltip from "nlib/ui/AutoTooltip";
import Badge from "nlib/ui/Badge";
import BulkActions from "./lib/BulkActions";
import Checkbox from "nlib/ui/Checkbox";
import CommentCell from "./lib/CommentCell";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import FileTypedIcon from "nlib/common/FileTypedIcon";
import Pages from "nlib/pages/Pages";
import React, { useCallback, useMemo, useState } from "react";
import Table, { TableCell, TableHead, TableRow } from "nlib/ui/Table";
import UploadedByCell from "./lib/UploadedByCell";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";
import useEnvVars from "hooks/useEnvVars";
import useShowCommonModal from "hooks/useShowCommonModal";

const {
  ARCHIVE_ATTACHMENT_TYPES: { TAX_STATEMENT, AGREEMENT, BANK_STATEMENT }
} = DataConstants;

const FILE_TYPE_THEMES = {
  [TAX_STATEMENT]: "warning",
  [AGREEMENT]: "positive",
  [BANK_STATEMENT]: "primary"
};

const VaultTable = ({ simplifyLayout, data, folders }) => {
  const location = useLocation();

  const dispatch = useDispatch();

  const showCommonModal = useShowCommonModal();

  const { restPath: folderPath } = Pages.getCurrentRouteInfo(location.pathname);

  const [{ sortBy, sortOrder, page = 1, pageSize = Constants.TABLE_PAGE_SIZE }, setEnvVars] = useEnvVars();

  const { uiTexts, messages } = useSelector(getTextsData);

  const [selectedItems, setSelectedItems] = useState([]);

  const allItemsSelected = !!data.length && data.every(({ id }) => selectedItems.includes(id));

  const sortedData = useMemo(() => {
    if (sortBy) {
      return Utils.arraySort(data, (item) => {
        switch (sortBy) {
          case "name":
            return item.attachment.originalName;
          case "type":
            return uiTexts[item.type];
          case "createdBy":
            return (item.createdBy || {}).fullName || "";
          default:
            return item.createdAt || "";
        }
      }, sortOrder === "asc");
    }

    return Utils.arraySort(data, "createdAt", true);
  }, [data, sortBy, uiTexts, sortOrder]);

  const getFolderPath = useCallback((folder) => {
    if (folder === Constants.PARENT_FOLDER_RELATIVE_PATH) {
      return folderPath.slice(0, folderPath.length - 1);
    }

    return [...folderPath, folder];
  }, [folderPath]);

  const handleSelectAllChange = useCallback(() => {
    setSelectedItems(
      allItemsSelected ? [] : data.map(({ id }) => id)
    );
  }, [allItemsSelected, data]);

  const handleSelectedChange = useCallback((value, event) => {
    event.stopPropagation();

    const { id } = event.target.dataset;

    setSelectedItems((prev) => value ? [...prev, id] : prev.filter((el) => el !== id));
  }, []);

  const handleEditClick = useCallback((editItem) => {
    setEnvVars({ editItem, editTask: null });
  }, [setEnvVars]);

  const handleRowClick = useCallback((event) => {
    handleEditClick(event.currentTarget.dataset.id);
  }, [handleEditClick]);

  const handleTableSortChange = useCallback((sortData) => {
    setEnvVars(sortData);
  }, [setEnvVars]);

  const handleBulkActionsClose = useCallback(async(result) => {
    if (result) {
      if (result.folder) {
        dispatch(ArchiveActions.bulkFilesUpdate(selectedItems, getFolderPath(result.folder)));
      }
      if (result.tags) {
        dispatch(ArchiveActions.bulkFilesUpdate(selectedItems, null, result.tags));
      }
      if (result.delete) {
        const modalResult = await showCommonModal({
          confirm: true,
          text: Utils.replaceTextVars(
            messages.bulkFilesDeleteConfirm,
            { filesCount: selectedItems.length }
          )
        });

        if (modalResult) {
          dispatch(ArchiveActions.bulkFilesUpdate(selectedItems));
        }
      }
    }
    setSelectedItems([]);
  }, [selectedItems, messages, dispatch, getFolderPath, showCommonModal]);

  const handlePageChange = useCallback((nextPage) => {
    setEnvVars({ page: nextPage });
  }, [setEnvVars]);

  const handlePageSizeChange = useCallback((nextPageSize) => {
    setEnvVars({ pageSize: nextPageSize });
  }, [setEnvVars]);

  return (
    <div className={Css.vaultTable}>
      {!!selectedItems.length && (
        <BulkActions
          folders={folders}
          selectedItems={selectedItems}
          onClose={handleBulkActionsClose} />
      )}
      <Table className={Css.table} sortBy={sortBy} sortOrder={sortOrder} onSortChange={handleTableSortChange}>
        <TableRow>
          <TableHead className={Css.checkboxCell}>
            {!!data.length && (
              <Checkbox
                checked={allItemsSelected}
                indeterminate={!!selectedItems.length}
                onChange={handleSelectAllChange} />
            )}
          </TableHead>
          <TableHead accessor="name" className={Css.nameCell}>
            {uiTexts.attachment}
          </TableHead>
          <TableHead accessor="type" className={Css.typeCell}>
            {uiTexts.type}
          </TableHead>
          <TableHead show={!simplifyLayout} accessor="description" className={Css.descriptionCell}>
            {uiTexts.description}
          </TableHead>
          <TableHead show={!simplifyLayout} accessor="tags" className={Css.tagsCell}>
            {uiTexts.tags}
          </TableHead>
          <TableHead show={!simplifyLayout} accessor="fromDate" className={Css.fromDateCell}>
            {uiTexts.fromDate}
          </TableHead>
          <TableHead show={!simplifyLayout} accessor="toDate" className={Css.toDateCell}>
            {uiTexts.toDate}
          </TableHead>
          <TableHead accessor="createdBy" className={Css.uploadedByCell}>
            {uiTexts.uploaded}
          </TableHead>
          <TableHead className={Css.commentsCell}>{uiTexts.comments}</TableHead>
          <TableHead className={Css.actionsCell}>{uiTexts.actions}</TableHead>
        </TableRow>
        {sortedData
          .slice((pageSize || 0) * (page - 1), (pageSize || 0) * page)
          .map((item) => {
            const {
              id,
              type,
              tags,
              toDate,
              notRead,
              fromDate,
              createdAt,
              description,
              attachment,
              comments = {},
              createdBy,
              attachment: { originalName } = {}
            } = item;

            const selected = selectedItems.includes(id);

            return (
              <TableRow
                key={id}
                data-id={id}
                className={classNames(
                  Css.row,
                  CommonCss.tableRow,
                  selected && CommonCss.selectedRow,
                  notRead && CommonCss.highlightRow
                )}
                onClick={handleRowClick}>
                <TableCell className={Css.checkboxCell}>
                  <Checkbox
                    checked={selected}
                    data-id={id}
                    onChange={handleSelectedChange} />
                </TableCell>
                <TableCell className={Css.nameCell}>
                  <FileTypedIcon className={Css.icon} fileName={originalName} />
                  <AutoTooltip>{originalName}</AutoTooltip>
                </TableCell>
                <TableCell className={Css.typeCell}>
                  <Badge title={(type && uiTexts[type]) || ""} theme={FILE_TYPE_THEMES[type]}>
                    {type ? uiTexts[type] : Constants.EMPTY_PLACEHOLDER}
                  </Badge>
                </TableCell>
                <TableCell show={!simplifyLayout} className={Css.descriptionCell}>
                  {description ? <AutoTooltip>{description}</AutoTooltip> : Constants.EMPTY_PLACEHOLDER}
                </TableCell>
                <TableCell show={!simplifyLayout} className={Css.tagsCell}>
                  {tags.length ? <AutoTooltip>{tags.join(", ")}</AutoTooltip> : Constants.EMPTY_PLACEHOLDER}
                </TableCell>
                <TableCell show={!simplifyLayout} className={Css.fromDateCell}>
                  <div className={Css.overflow}>
                    {fromDate
                      ? moment.utc(fromDate).format(Constants.DATETIME_FORMATS.DATE_TEXT)
                      : Constants.EMPTY_PLACEHOLDER}
                  </div>
                </TableCell>
                <TableCell show={!simplifyLayout} className={Css.toDateCell}>
                  <div className={Css.overflow}>
                    {toDate
                      ? moment.utc(toDate).format(Constants.DATETIME_FORMATS.DATE_TEXT)
                      : Constants.EMPTY_PLACEHOLDER}
                  </div>
                </TableCell>
                <TableCell className={Css.uploadedByCell}>
                  {createdBy ? (
                    <UploadedByCell
                      createdBy={createdBy}
                      createdAt={createdAt} />
                  ) : Constants.EMPTY_PLACEHOLDER}
                </TableCell>
                <TableCell className={Css.commentsCell}>
                  <CommentCell id={id} comments={comments} />
                </TableCell>
                <TableCell className={Css.actionsCell}>
                  <ActionsCell
                    itemId={id}
                    attachment={attachment}
                    onEdit={handleEditClick} />
                </TableCell>
              </TableRow>
            );
          })}
      </Table>
      <div className={Css.footer}>
        {(sortedData.length > Number(pageSize)) && (
          <Pagination
            className={Css.pagination}
            count={sortedData.length}
            page={Number(page)}
            pageSize={Number(pageSize)}
            onChange={handlePageChange} />
        )}
        {(sortedData.length > Constants.TABLE_PAGE_SIZE) && (
          <SelectPageSize
            className={Css.pageSize}
            pageSize={Number(pageSize)}
            onChange={handlePageSizeChange} />
        )}
      </div>
    </div>
  );
};

export default React.memo(VaultTable);
