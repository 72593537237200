import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { checkIsBusinessUser } from "selectors/user";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import Card from "nlib/common/Card";
import ChangeFolderSelector from "../ChangeFolderSelector";
import CreateFolderForm from "nlib/pages/VaultPage/lib/CreateFolderForm";
import React, { useCallback, useState } from "react";
import TagsInput from "nlib/ui/TagsInput";
import Utils from "utils/Utils";

const BulkActions = ({ folders, selectedItems, onClose }) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const businessUser = useSelector(checkIsBusinessUser);

  const [createFolderMode, setCreateFolderMode] = useState(false);

  const [tags, setTags] = useState([]);

  const [inputValue, setInputValue] = useState("");

  const onCreateFolder = useCallback((folder) => {
    setCreateFolderMode(true);
    onClose({ folder });
  }, [onClose]);

  const handleCancelClick = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleDeleteClick = useCallback(() => {
    onClose({ delete: true });
  }, [onClose]);

  const handleEditClick = useCallback(() => {
    onClose({ tags });
  }, [tags, onClose]);

  const handleFolderChange = useCallback((value) => {
    if (value) onClose({ folder: value });
    else setCreateFolderMode(true);
  }, [onClose]);

  const handleTagsChange = useCallback((value) => {
    setTags(value);
  }, []);

  return (
    <Card className={Css.bulkActions}>
      <div className={Css.text}>
        {uiTexts.selected}: {selectedItems.length}
        {Utils.replaceTextVars(messages.selected, { count: selectedItems.length })}
      </div>
      <div className={Css.inputs}>
        <CreateFolderForm
          opened={createFolderMode}
          setOpened={setCreateFolderMode}
          className={Css.createFolderForm}
          folders={folders}
          onCreateFolder={onCreateFolder}>
          {() => (
            <ChangeFolderSelector
              className={Css.changeFolderSelector}
              folders={folders}
              onChange={handleFolderChange} />
          )}
        </CreateFolderForm>
        <div className={Css.input}>
          <TagsInput
            className={Css.tagsInput}
            inputValue={inputValue}
            value={tags}
            placeholder={uiTexts.addTags}
            setInputValue={setInputValue}
            onChange={handleTagsChange} />
          <Button
            large
            primary
            icon={Icons.FloppyDisk}
            className={Css.button}
            disabled={!inputValue && !tags.length}
            onClick={handleEditClick}>
            {uiTexts.save}
          </Button>
        </div>
      </div>
      <div className={Css.buttons}>
        <Button
          large primary light
          icon={Icons.X}
          className={Css.button}
          onClick={handleCancelClick}>
          {uiTexts.cancel}
        </Button>
        {!businessUser && (
          <Button
            large danger
            icon={Icons.Trash}
            className={Css.button}
            onClick={handleDeleteClick}>
            {uiTexts.delete}
          </Button>
        )}
      </div>
    </Card>
  );
};

export default React.memo(BulkActions);
