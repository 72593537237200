import Constants from "const/Constants";
import Css from "./style.module.scss";

import { Avatar, Tooltip } from "nlib/ui";
import React from "react";
import moment from "moment";

const UploadedByCell = ({ createdBy, createdAt }) => {
  const { fullName, email } = createdBy || {};

  const createdAtText = createdAt ? moment(createdAt).format(Constants.DATETIME_FORMATS.DATETIME_TEXT) : null;

  return (
    <div className={Css.uploadedByCell}>
      <Avatar title={fullName || email} size="small" />
      <Tooltip className={Css.tooltip} opened>
        <div>{fullName || email}</div>
        {createdAtText && <div>{createdAtText}</div>}
      </Tooltip>
    </div>
  );
};

export default React.memo(UploadedByCell);
