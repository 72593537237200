import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import Input from "nlib/ui/Input";
import React, { useCallback, useMemo, useState } from "react";
import Tooltip from "nlib/ui/Tooltip";
import classNames from "classnames";

const EXCLUDED_CHARACTERS_PATTERN = /[.,/\\]+/g;

const CreateFolderForm = (props) => {
  const [createFolderMode, setCreateFolderMode] = useState(false);

  const {
    opened = createFolderMode,
    setOpened = setCreateFolderMode,
    className,
    folders,
    children,
    onCreateFolder
  } = props;

  const { uiTexts } = useSelector(getTextsData);

  const [folderName, setFolderName] = useState("");

  const folderNameTrimmed = folderName.trim();

  const uniqueFolderName = useMemo(() => {
    return !folders.some(({ name }) => {
      return name && folderName && name.trim().toLowerCase() === folderName.trim().toLowerCase();
    });
  }, [folders, folderName]);

  const handleButtonClick = useCallback(() => {
    setOpened(true);
  }, [setOpened]);

  const handleFolderNameChange = useCallback((value) => {
    setFolderName(value.replace(EXCLUDED_CHARACTERS_PATTERN, ""));
  }, []);

  const handleCancelButtonClick = useCallback(() => {
    setFolderName("");
    setOpened(false);
  }, [setOpened]);

  const handleCreateButtonClick = useCallback(() => {
    setOpened(false);
    setFolderName("");
    onCreateFolder(folderNameTrimmed);
  }, [setOpened, folderNameTrimmed, onCreateFolder]);

  if (opened) {
    return (
      <div className={classNames(Css.createFolderForm, className)}>
        <div className={Css.inputWrapper}>
          <Input
            autoFocus
            className={Css.input}
            value={folderName}
            invalid={!uniqueFolderName}
            placeholder={uiTexts.enterFolderName}
            onChange={handleFolderNameChange} />
          <Tooltip className={Css.tooltip} opened={!uniqueFolderName} place="bottom">
            <Icons.Warning />
            <span>{uiTexts.nameIsNotUnique}</span>
          </Tooltip>
        </div>
        <Button
          large primary
          disabled={!folderNameTrimmed || !uniqueFolderName}
          className={Css.button}
          onClick={handleCreateButtonClick}>
          {uiTexts.create}
        </Button>
        <Button
          large outline
          className={Css.button}
          onClick={handleCancelButtonClick}>
          {uiTexts.cancel}
        </Button>
      </div>
    );
  }

  return children({ onClick: handleButtonClick });
};

export default React.memo(CreateFolderForm);
